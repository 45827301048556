import React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { Section, Container } from "../components/Section";
import CheckoutForm from "../components/CheckoutForm";

const CheckoutPage = ({ location }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.panhandlemetals.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Checkout",
        item: `https://www.panhandlemetals.com/faq/`,
      },
    ],
  };
  const schema = [breadcrumbSchema];
  return (
    <Layout location={location}>
      <Seo title="Checkout" description="Checkout" schemaMarkup={schema} />
      <Section
        ept="100px"
        epb="0"
        xpt="100px"
        xpb="0"
        pt="64px"
        pb="0"
        bgColor="#fff"
        className="section-checkout"
      >
        <Container>
          <CheckoutForm
            location={location}
            thanksURL="/thank-you"
          />
        </Container>
      </Section>
    </Layout>
  );
};

export default CheckoutPage;
